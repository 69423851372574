@font-face {
    font-family: 'Blender Pro';
    src: url('../public/font/BlenderPro-BoldItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Blender Pro';
    src: url('../public/font/BlenderPro-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Blender Pro';
    src: url('../public/font/BlenderPro-Medium.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Blender Pro';
    src: url('../public/font/BlenderPro-BookItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Blender Pro';
    src: url('../public/font/BlenderPro-Book.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Blender Pro';
    src: url('../public/font/BlenderPro-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Blender Pro';
    src: url('../public/font/BlenderPro-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}
